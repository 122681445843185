<template>
  <v-app style="background-color:#f8f9fb" >


  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 365 dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showFilterDownload" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        Em Manutenção
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilterDownload = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


    <v-container style="background-color:#f8f9fb;" >
      <v-col cols="12">
        <filtro-component :opcoes="opcoes" :produtos="tipos_funerarios" :modos="modos"  :tipos="tipos" :calendar="'mes'"
        @filtrar="filterData" :title_opcao="'Campanha'" :campos="'N'"
          ></filtro-component>
      </v-col>
    </v-container>

     
 


  <v-container style="background-color:white;"  
    id="financeiro" 
    fluid
    tag="section">



        <br/><br/>
 
        <v-progress-linear v-if="getLiberado() == false"  style="width: 50%; margin-left:25%"
          v-model="this.carregado"
          color="blue-grey"
          height="50" :max="100"
        >
          <template v-slot:default="{  }" >
            <strong> Carregando - {{ Math.ceil(parseFloat(carregado)) }}%</strong>
          </template>
          
        </v-progress-linear>
        
        

        

      <v-row align-self="center">
            <v-col style="padding:2px !important; text-align: center" 
              cols="12" 
              sm="12"
              lg="10">
              <div style="text-align: center;">

      
              </div>

             
              <table v-if="getLiberado()" >
               
                  <thead>
                    <tr >
                        <th style="font-size: 13px !important; background-color: white !important; color: white !important "></th>
                        <th  style="background-color: white;"></th>

                        <th colspan="3" style="max-width: 400px; text-align: center; background-color: #6885A1; color: white !important">
                            {{ this.selectedStartDate }} - {{ this.selectedEndDate }}
                        </th>
                        <th ></th>
                        <th colspan="3" style="max-width: 400px; text-align: center; background-color: #6885A1; color: white !important">
                            {{ dataLabel(this.selectedStartDate, 1) }} - {{ dataLabel(this.selectedEndDate, 1) }}
                        </th>
                        <th></th>
                        <th colspan="3" style="max-width: 400px; text-align: center; background-color: #6885A1; color: white !important">
                            {{ dataLabel(this.selectedStartDate, 2) }} - {{ dataLabel(this.selectedEndDate, 2) }}
                        </th>
                      </tr>
                  </thead>
                  <tr></tr>
                  <tr></tr>
                
                  <thead>
                      <tr style="font-size: 13px !important; background-color: white !important; ">
                        <th   style="background-color: #4782b447;">Documentos com Vencimento no Período</th>
                        <th  style="background-color: white;"></th>

                        <th colspan="3" style="max-width: 400px; text-align: center; background-color: #4782b447;">
                          {{ this.esperado_periodo_v2 }} (Documentos)
                        </th>
                        <th></th>
                        <th colspan="3" style="max-width: 400px; text-align: center; background-color: #4782b447;">
                          {{ this._1_esperado_periodo_v2 }} (Documentos)
                        </th>
                        <th></th>
                        <th colspan="3" style="max-width: 400px; text-align: center; background-color: #4782b447;">
                          {{ this._2_esperado_periodo_v2 }} (Documentos)
                        </th>
                      </tr>
                  </thead>

                  <tr></tr>
                  <tr></tr>

                  <thead>
                    <tr style="font-size: 13px">
                      <th style="min-width: 280px;">Resumo</th>
                      <th  style="background-color: white;"></th>
                          <th style="max-width: 120px;">Qtd</th>
                          <th style="max-width: 120px;">% Em Relação ao Total Emitido</th>
                          <th style="max-width: 120px;">% Inadimp. ao Total Emitido</th>
                          <th  style="background-color: white;"></th>
                          <th style="max-width: 120px;">Qtd</th>
                          <th style="max-width: 120px;">% Em Relação ao Total Emitido</th>
                          <th style="max-width: 120px;">% Inadimp. ao Total Emitido</th>
                          <th  style="background-color: white;"></th>
                          <th style="max-width: 120px;">Qtd</th>
                          <th style="max-width: 120px;">% Em Relação ao Total Emitido</th>
                          <th style="max-width: 120px;">% Inadimp. ao Total Emitido</th>

                      </tr>
                  </thead>
                  <tbody style="font-size: 13px">
                    <br/>
                    

                    <tr style="background-color: #4782b447;">
                          <td>Pagos anterior ao período(considerar sempre mês anterior)</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;">{{ this.recebido_adiantado_vencimento_v2 }}</td>
                          <td style="text-align: end;">{{ this.calcularPercentual(this.recebido_adiantado_vencimento_v2, this.esperado_periodo_v2)  }} </td>
                          <td style="text-align: end;">0,00%</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;">{{ this._1_recebido_adiantado_vencimento_v2 }}</td>
                          <td style="text-align: end;">{{ this.calcularPercentual(this._1_recebido_adiantado_vencimento_v2, this._1_esperado_periodo_v2)  }} </td>
                          <td style="text-align: end;">0,00%</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;"><span v-if="this.bool_02">{{ this._2_recebido_adiantado_vencimento_v2 }}</span></td>
                          <td style="text-align: end;"><span v-if="this.bool_02">{{ this.calcularPercentual(this._2_recebido_adiantado_vencimento_v2, this._2_esperado_periodo_v2)  }} </span>  </td>
                          <td style="text-align: end;">0,00%</td>
                      </tr>

                      <tr style="background-color: #b0c6da47;">
                          <td>Pagos no período</td>
                          <th style="background-color: white;"></th>

                          <td style="text-align: end;">{{this.recebido_periodo_vencimento_v2}}</td>
                          <td style="text-align: end;">{{ this.calcularPercentual(this.recebido_periodo_vencimento_v2, this.esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;">0,00%</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;">{{this._1_recebido_periodo_vencimento_v2}}</td>
                          <td style="text-align: end;">{{ this.calcularPercentual(this._1_recebido_periodo_vencimento_v2, this._1_esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;">0,00%</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;"><span v-if="this.bool_02">{{this._2_recebido_periodo_vencimento_v2}}</span> </td>
                          <td style="text-align: end;"><span v-if="this.bool_02">{{ this.calcularPercentual(this._2_recebido_periodo_vencimento_v2, this._2_esperado_periodo_v2)  }}</span> </td>
                          <td style="text-align: end;">0,00%</td>
                      </tr>
                    
                      
                      <tr>
                          <td style="text-align: end;font-weight: 400; background-color: white;">Total Parcial</td>
                          <th  style="background-color: white;"></th>

                          <td style="text-align: end;font-weight: 400; background-color: white;"> {{(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2)}}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;"> {{ this.calcularPercentual(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2, this.esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;"> {{ this.calcularPercentualInad(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2, this.esperado_periodo_v2)  }}</td>
                          <th  style="background-color: white;"></th>
                            <td style="text-align: end;font-weight: 400; background-color: white;"> {{(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2)}}</td>
                            <td style="text-align: end;font-weight: 400; background-color: white;"> {{ this.calcularPercentual(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2, this._1_esperado_periodo_v2)  }}</td>
                            <td style="text-align: end;font-weight: 400; background-color: white;"> {{ this.calcularPercentualInad(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2, this._1_esperado_periodo_v2)  }}</td>
                            <th  style="background-color: white;"></th>
                              
                              <td style="text-align: end;font-weight: 400; background-color: white;"> <span v-if="this.bool_02"> {{(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2) }}</span> </td>
                              <td style="text-align: end;font-weight: 400; background-color: white;"> <span v-if="this.bool_02">{{ this.calcularPercentual(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2, this._2_esperado_periodo_v2)  }}</span> </td>
                              <td style="text-align: end;font-weight: 400; background-color: white;"> <span v-if="this.bool_02">{{ this.calcularPercentualInad(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2, this._2_esperado_periodo_v2)  }}</span> </td>
                      </tr>
                      <br/>
                      <tr style="background-color: #4782b447;">
                          <td>Pago até 30 dias de atraso</td>
                          <th  style="background-color: white;"></th>

                          <td style="text-align: end;">{{(this.total_recebimento_30_v2)}}</td>
                          <td style="text-align: end;">{{ this.calcularPercentual(this.total_recebimento_30_v2, this.esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;">0%</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;">{{(this._1_total_recebimento_30_v2)}}</td>
                          <td style="text-align: end;">{{ this.calcularPercentual(this._1_total_recebimento_30_v2, this._1_esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;">0%</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;"><span v-if="this.bool_02">{{(this._2_total_recebimento_30_v2)}}</span> </td>
                          <td style="text-align: end;"><span v-if="this.bool_02">{{ this.calcularPercentual(this._2_total_recebimento_30_v2, this._2_esperado_periodo_v2)  }}</span> </td>
                          <td style="text-align: end;">0%</td>

                      </tr>

                    
                      <tr >
                          <td style="text-align: end;font-weight: 400; background-color: white;">Total Parcial</td>
                          <th  style="background-color: white;"></th>

                          <td style="text-align: end;font-weight: 400; background-color: white;"> {{(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2 + this.total_recebimento_30_v2)}}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;">{{ this.calcularPercentual(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2 + this.total_recebimento_30_v2, this.esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;">{{ this.calcularPercentualInad(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2 + this.total_recebimento_30_v2, this.esperado_periodo_v2)  }}</td>
                          <th  style="background-color: white;"></th>
                        
                          <td style="text-align: end;font-weight: 400; background-color: white;"> {{(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2 + this.total_recebimento_30_v2)}}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;">{{ this.calcularPercentual(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2 + this._1_total_recebimento_30_v2, this._1_esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;">{{ this.calcularPercentualInad(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2 + this._1_total_recebimento_30_v2, this._1_esperado_periodo_v2)  }}</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;font-weight: 400; background-color: white;"> <span v-if="this.bool_02">{{(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2 + this._2_total_recebimento_30_v2)}} </span>  </td>
                          <td style="text-align: end;font-weight: 400; background-color: white;"> <span v-if="this.bool_02">{{ this.calcularPercentual(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2 + this._2_total_recebimento_30_v2, this._2_esperado_periodo_v2)  }} </span> </td>
                          <td style="text-align: end;font-weight: 400; background-color: white;"> <span v-if="this.bool_02">{{ this.calcularPercentualInad(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2 + this._2_total_recebimento_30_v2, this._2_esperado_periodo_v2)  }}</span> </td>
                      </tr>
                      <br/>

                      <tr style="background-color: #4782b447;">
                          <td>Pago entre 31 e 60 dias de atraso</td>
                          <th  style="background-color: white;"></th>

                          <td style="text-align: end;">{{(this.total_recebimento_30_60_v2)}}</td>
                          <td style="text-align: end;">{{ this.calcularPercentual(this.total_recebimento_30_60_v2, this.esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;">0%</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;">{{(this._1_total_recebimento_30_60_v2)}}</td>
                          <td style="text-align: end;">{{ this.calcularPercentual(this._1_total_recebimento_30_60_v2, this._1_esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;">0%</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;"><span v-if="this.bool_02">{{(this._2_total_recebimento_30_60_v2)}}</span> </td>
                          <td style="text-align: end;"><span v-if="this.bool_02">{{ this.calcularPercentual(this._2_total_recebimento_30_60_v2, this._2_esperado_periodo_v2)  }}</span> </td>
                          <td style="text-align: end;">0%</td>
                      </tr>

                    
                      <tr >
                          <td style="text-align: end;font-weight: 400; background-color: white;">Total Parcial</td>
                          <th  style="background-color: white;"></th>

                          <td style="text-align: end;font-weight: 400; background-color: white;"> {{(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2 + this.total_recebimento_30_v2 + total_recebimento_30_60_v2)}}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;">{{ this.calcularPercentual(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2 + this.total_recebimento_30_v2 + total_recebimento_30_60_v2, this.esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;">{{ this.calcularPercentualInad(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2 + this.total_recebimento_30_v2 + total_recebimento_30_60_v2, this.esperado_periodo_v2)  }}</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;font-weight: 400; background-color: white;"> {{(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2 + this._1_total_recebimento_30_v2 + _1_total_recebimento_30_60_v2)}}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;">{{ this.calcularPercentual(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2 + this._1_total_recebimento_30_v2 + _1_total_recebimento_30_60_v2, this._1_esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;">{{ this.calcularPercentualInad(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2 + this._1_total_recebimento_30_v2 + _1_total_recebimento_30_60_v2, this._1_esperado_periodo_v2)  }}</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;font-weight: 400; background-color: white;"><span v-if="this.bool_02"> {{(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2 + this._2_total_recebimento_30_v2 + this._2_total_recebimento_30_60_v2)}}</span> </td>
                          <td style="text-align: end;font-weight: 400; background-color: white;"><span v-if="this.bool_02">{{ this.calcularPercentual(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2 + this._2_total_recebimento_30_v2 + _2_total_recebimento_30_60_v2, this._2_esperado_periodo_v2)  }}</span> </td>
                          <td style="text-align: end;font-weight: 400; background-color: white;"><span v-if="this.bool_02">{{ this.calcularPercentualInad(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2 + this._2_total_recebimento_30_v2 + _2_total_recebimento_30_60_v2, this._2_esperado_periodo_v2)  }}</span> </td>
                      </tr>

                      <br/>

                      <tr style="background-color: #4782b447;">
                          <td>Pago com mais de 60 dias de atraso</td>
                          <th  style="background-color: white;"></th>

                          <td style="text-align: end;">{{(this.total_recebimento_60_v2)}}</td>
                          <td style="text-align: end;">{{ this.calcularPercentual(this.total_recebimento_60_v2, this.esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;">0%</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;">{{(this._1_total_recebimento_60_v2)}}</td>
                          <td style="text-align: end;">{{ this.calcularPercentual(this._1_total_recebimento_60_v2, this._1_esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;">0%</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;"><span v-if="this.bool_02">{{(this._2_total_recebimento_60_v2)}}</span> </td>
                          <td style="text-align: end;"><span v-if="this.bool_02">{{ this.calcularPercentual(this._2_total_recebimento_60_v2, this._2_esperado_periodo_v2)  }}</span> </td>
                          <td style="text-align: end;">0%</td>
                      </tr>

                  
                      <tr >
                          <td style="text-align: end;font-weight: 400; background-color: white;">Total Parcial</td>
                          <th  style="background-color: white;"></th>

                          <td style="text-align: end;font-weight: 400; background-color: white;"> {{(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2 + this.total_recebimento_30_v2 + this.total_recebimento_30_60_v2 + this.total_recebimento_60_v2)}}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;">{{ this.calcularPercentual(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2 + this.total_recebimento_30_v2 + this.total_recebimento_30_60_v2 + this.total_recebimento_60_v2, this.esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;">{{ this.calcularPercentualInad(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2 + this.total_recebimento_30_v2 + this.total_recebimento_30_60_v2 + this.total_recebimento_60_v2, this.esperado_periodo_v2)  }}</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;font-weight: 400; background-color: white;"> {{(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2 + this._1_total_recebimento_30_v2 + this._1_total_recebimento_30_60_v2 + this._1_total_recebimento_60_v2)}}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;">{{ this.calcularPercentual(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2 + this._1_total_recebimento_30_v2 + this._1_total_recebimento_30_60_v2 + this._1_total_recebimento_60_v2, this._1_esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;">{{ this.calcularPercentualInad(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2 + this._1_total_recebimento_30_v2 + this._1_total_recebimento_30_60_v2 + this._1_total_recebimento_60_v2, this._1_esperado_periodo_v2)  }}</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;font-weight: 400; background-color: white;"> {{(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2 + this._2_total_recebimento_30_v2 + this._2_total_recebimento_30_60_v2 + this._2_total_recebimento_60_v2)}}</td>
                          <td style="text-align: end;font-weight: 400; background-color: white;"><span v-if="this.bool_02">{{ this.calcularPercentual(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2 + this._2_total_recebimento_30_v2 + this._2_total_recebimento_30_60_v2 + this._2_total_recebimento_60_v2, this._2_esperado_periodo_v2)  }}</span> </td>
                          <td style="text-align: end;font-weight: 400; background-color: white;"><span v-if="this.bool_02">{{ this.calcularPercentualInad(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2 + this._2_total_recebimento_30_v2 + this._2_total_recebimento_30_60_v2 + this._2_total_recebimento_60_v2, this._2_esperado_periodo_v2)  }}</span> </td>


                      </tr>

                      <br/>

                      <tr style="background-color: #4782b447;">
                          <td>Inadimplência atual</td>
                          <th  style="background-color: white;"></th>

                          <td style="text-align: end;"> {{(this.inadimplencia_acumulda)}}</td>
                          <td style="text-align: end;">{{ this.calcularPercentual(this.inadimplencia_acumulda, this.esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;"></td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;"> {{(this._1_inadimplencia_acumulda)}}</td>
                          <td style="text-align: end;">{{ this.calcularPercentual(this._1_inadimplencia_acumulda, this._1_esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;"></td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;"> <span v-if="this.bool_02"> {{(this._2_inadimplencia_acumulda)}}</span> </td>
                          <td style="text-align: end;"><span v-if="this.bool_02">{{ this.calcularPercentual(this._2_inadimplencia_acumulda, this._2_esperado_periodo_v2)  }}</span> </td>
                          <td style="text-align: end;"></td>


                      </tr>

                      <br/>

                      <tr style="background-color: #f2f2f2 !important;">
                          <td style="text-align: end;font-weight: 800; background-color: white; background-color: #f2f2f2">Total</td>
                          <th  style="background-color: white;"></th>

                          <td style="text-align: end;font-weight: 800; background-color: white; background-color: #f2f2f2"> {{(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2 + this.total_recebimento_30_v2 + this.total_recebimento_30_60_v2 + this.total_recebimento_60_v2 + this.inadimplencia_acumulda)}}</td>
                          <td style="text-align: end;font-weight: 800; background-color: white; background-color: #f2f2f2">{{this.calcularPercentual(this.recebido_periodo_vencimento_v2 + this.recebido_adiantado_vencimento_v2 + this.total_recebimento_30_v2 + this.total_recebimento_30_60_v2 + this.total_recebimento_60_v2 + this.inadimplencia_acumulda, this.esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;font-weight: 800; background-color: white; background-color: #f2f2f2"> 0%</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;font-weight: 800; background-color: white; background-color: #f2f2f2"> {{(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2 + this._1_total_recebimento_30_v2 + this._1_total_recebimento_30_60_v2 + this._1_total_recebimento_60_v2 + this._1_inadimplencia_acumulda)}}</td>
                          <td style="text-align: end;font-weight: 800; background-color: white; background-color: #f2f2f2">{{this.calcularPercentual(this._1_recebido_periodo_vencimento_v2 + this._1_recebido_adiantado_vencimento_v2 + this._1_total_recebimento_30_v2 + this._1_total_recebimento_30_60_v2 + this._1_total_recebimento_60_v2 + this._1_inadimplencia_acumulda, this._1_esperado_periodo_v2)  }}</td>
                          <td style="text-align: end;font-weight: 800; background-color: white; background-color: #f2f2f2"> 0%</td>
                          <th  style="background-color: white;"></th>
                          <td style="text-align: end;font-weight: 800; background-color: white; background-color: #f2f2f2"><span v-if="this.bool_02"> {{(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2 + this._2_total_recebimento_30_v2 + this._2_total_recebimento_30_60_v2 + this._2_total_recebimento_60_v2 + this._2_inadimplencia_acumulda)}} </span> </td>
                          <td style="text-align: end;font-weight: 800; background-color: white; background-color: #f2f2f2"><span v-if="this.bool_02">{{this.calcularPercentual(this._2_recebido_periodo_vencimento_v2 + this._2_recebido_adiantado_vencimento_v2 + this._2_total_recebimento_30_v2 + this._2_total_recebimento_30_60_v2 + this._2_total_recebimento_60_v2 + this._2_inadimplencia_acumulda, this._2_esperado_periodo_v2)  }} </span> </td>
                          <td style="text-align: end;font-weight: 800; background-color: white; background-color: #f2f2f2"> 0%</td>
                      </tr>


                  </tbody>
                </table>

            </v-col>

            
      </v-row>      

      

      <br/><br/><br/>
    
  </v-container>

</v-app> 
</template>
<script>
  import VueApexCharts from "vue-apexcharts";
  import Inadimplencia from '../../services/inadimplencia';
  import { ContentLoader } from 'vue-content-loader';
  import CardLoadComponent from "./components/CardLoad.vue"; // Certifique-se de que o caminho esteja correto
  import FiltroComponent from "./components/FiltroGeral.vue"; // Certifique-se de que o caminho esteja correto


  export default {
    name: 'Inadimplência',
    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,

    },

    created() {
      this.selectedStartDate = this.getFirstDayOfMonth(),
      this.selectedEndDate =  this.formatDateBR(new Date())
      this.selectedStartDate2 = this.getFirstDayOfMonth(),
      this.selectedEndDate2 =  this.formatDateBR(new Date())
    },


    
    mounted() {

          this.carregado = 0;
          this.dashboard();
          this.dashboard_1();
          this.dashboard_2();
          this.handleFilterChange(this.selectMode, this.selectedStartDate, this.selectedEndDate, this.selectedStartDate2, this.selectedEndDate2);
    },
   

    data () {
      return {

        tab: 0, 
         carregado: 0,
         produto: 'Todos',
        
        
         bool_01 : false,
         bool_02 : false,
         bool_03 : false,
         bool_04 : false,
         bool_05 : false,
         bool_06 : false,
         bool_07 : false,
         bool_08 : false,
         bool_09 : false,
         bool_10 : false,
         bool_11 : false,
         bool_12 : false,
         bool_13 : false,
         bool_14 : false,
         bool_15 : false,
         bool_16 : false,
         bool_17 : false,
         bool_18 : false,
         bool_19 : false,
         bool_20 : false,
         bool_21 : false,


         card1_loading:true,
        
         recebido_adiantado_vencimento_v2: 0,
         inadimplencia_acumulda:0,
         esperado_periodo_v2:0,
         recebido_periodo_vencimento_v2:0,
         total_recebimento_30_v2: 0,
         total_recebimento_30_60_v2: 0,
         total_recebimento_60_v2: 0, 


         _1_recebido_adiantado_vencimento_v2: 0,
         _1_inadimplencia_acumulda:0,
         _1_esperado_periodo_v2:0,
         _1_recebido_periodo_vencimento_v2:0,
         _1_total_recebimento_30_v2: 0,
         _1_total_recebimento_30_60_v2: 0,
         _1_total_recebimento_60_v2: 0, 


         _2_recebido_adiantado_vencimento_v2: 0,
         _2_inadimplencia_acumulda:0,
         _2_esperado_periodo_v2:0,
         _2_recebido_periodo_vencimento_v2:0,
         _2_total_recebimento_30_v2: 0,
         _2_total_recebimento_30_60_v2: 0,
         _2_total_recebimento_60_v2: 0, 


         animationInterval: null, // Intervalo para controlar a animação

          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          opcoes: ["Todas","Campanha 50 Anos"],
          selectMode: "Vencimento",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          modos: ["Período"],
          modos2: ["SIM", "NÃO"],
          campos: 'N',
          categoria: [],
          showFilter: false,
          showFilterDownload: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos", "Planos", "Planos Florais", "Jazigo", "Cremação", "Funerária", "Serviços Administrativos e Cemiteriais"],
          tipos_funerarios: [
                "Todos",
                "COMPLETO",
                "CONCESSÃO DE COLUMBÁRIO",
                "CONCESSÃO DE JAZIGOS",
                "CONCESSÃO DE OSSÁRIO",
                "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS",
                "URNAS CINERARIAS",
                "VENDA DE COLUMBÁRIO",
                "VENDA DE JAZIGOS - IMEDIATO",
                "VENDA DE JAZIGOS - PREVENTIVO",
                "VENDA DE JAZIGOS - TRANSITÓRIO"
              ],

          gf_1_series: [
                      {
                        name: "Memorial Parque Maceió",
                        data: [],
                        color: '#008000',
                      },
                      {
                        name: "Previda",
                        data: [],
                        color: '#0000FF',
                      },
                      {
                        name: "Parque do Agreste",
                        data: [],
                        color: '#808080',
                      },
                      {
                        name: "Cemiterio Previda",
                        data: [],
                        color: '#800080',
                      },
                        {
                        name: "Parque das Flores",
                        data: [],
                        color: '#FF8C00',
                      },
                    ],
            
          lista_valores_mes_gf: {
                      valores: [],
           },

           lista_nome_empresa:[],
      
      }
    },

   
    methods: {
     
      

        isDateValid(dateString) {
          // Verifica se a string é uma data válida
          const date = new Date(dateString);
          return !isNaN(date.getTime());
        },
        areDatesValid(dates) {
          // Verifica se todas as datas na matriz são válidas
          return dates.every(dateString => {
            const date = new Date(dateString);
            return !isNaN(date.getTime());
          });
        },

        handleFilterChange(selectedFilter, dataI, dataF, DataRI, DataRF) {

         

          if (selectedFilter === 'Vencimento') {
            this.subTextAddDynamic = 'Total Recebido: ';
          } else if (selectedFilter != 'Vencimento') {

            if (
              converteDataParaFormatoISO(DataRI) <= converteDataParaFormatoISO(dataI) &&
              converteDataParaFormatoISO(DataRF) > converteDataParaFormatoISO(dataF)
              ) {
                this.subTextAddDynamic = 'Total Recebido com Acréscimo: ';
              }else{
                this.subTextAddDynamic = 'Total Recebido: ';

              }
          }

        },

       

      download_card1(){
        this.showFilterDownload = true;
      },
    
      filterData(valores) {
        
          this.carregarLoad(),
          this.isLoading = true;
          let nova_consulta = false;

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;

          this.selectMode = "Vencimento";


          this.handleFilterChange(valores.selectMode, valores.selectedStartDate, valores.selectedEndDate, valores.selectedStartDate2, valores.selectedEndDate2);
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;
          this.produto = valores.selectedProduto;
   
          this.dashboard();
          this.dashboard_1();
          this.dashboard_2();
      },

     
      getLiberado(){

        if (this.bool_01 && this.bool_02 && this.bool_03 && this.bool_04 && this.bool_05 && this.bool_06 && this.bool_07 && this.bool_08 && this.bool_09 && this.bool_10 && this.bool_11 && this.bool_12 && this.bool_13 && this.bool_14 && this.bool_15 && this.bool_16 && this.bool_17 && this.bool_18 && this.bool_19 && this.bool_20 && this.bool_21) {
            return true
        }else 
            return false

      },
     
        getFirstDayOfMonth() {
          const dataAtual = new Date();
          const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
          return this.formatDateBR(primeiroDiaDoMes);
        },

        startAnimation(targetValue) {
            this.animationInterval = setInterval(() => {
              if (this.animatedValue < targetValue) {
                this.animatedValue += 1; // Atualiza o valor animado
              } else {
                clearInterval(this.animationInterval); // Interrompe a animação quando atingir o valor desejado
              }
            }, 10); // Intervalo em milissegundos entre cada atualização
          },


        formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },



      carregarLoad(){

         this.carregado = 0;
         this.bool_01 = false,
         this.bool_02 = false,
         this.bool_03 = false,
         this.bool_04 = false,
         this.bool_05 = false,
         this.bool_06 = false,
         this.bool_07 = false,
         this.bool_08 = false,
         this.bool_09 = false,
         this.bool_10 = false,
         this.bool_11 = false,
         this.bool_12 = false,
         this.bool_13 = false,
         this.bool_14 = false,
         this.bool_15 = false,
         this.bool_16 = false,
         this.bool_17 = false,
         this.bool_18 = false,
         this.bool_19 = false,
         this.bool_20 = false,
         this.bool_21 = false

      },

      async dashboard() {

              this.selectMode = "Vencimento";
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

              const formattedStartDate2 = converteDataParaFormatoISO(this.selectedStartDate2);
              const formattedEndDate2 = converteDataParaFormatoISO(this.selectedEndDate2);
              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                      if (diferencaEmDias > 365) {
                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        return 
                      }

                    
                            
                        Inadimplencia.total_esperado_periodo_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.esperado_periodo_v2 = response.data;                                
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.bool_01 = true
                            this.carregado = this.carregado + 5
                          })  




                          Inadimplencia.total_recebimento_vencimento_v2(formattedStartDate, formattedEndDate,  this.selectedCompany, this.selectedType, "Vencimento", null, null, this.selectedOpcao, this.produto).then(response => {

                              if(response.status === 200){
                                this.recebido_periodo_vencimento_v2 = response.data;      
                              } 

                              }).catch(e => {    
                              }).finally(() => {
                                this.bool_02 = true
                                this.carregado = this.carregado + 4.60
                              })  

                          Inadimplencia.inadimplencia_acumulda(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Vencimento", this.selectedOpcao, this.produto).then(response => {

                              if(response.status === 200){
                                this.inadimplencia_acumulda = response.data;      
                              } 

                              }).catch(e => {    
                              }).finally(() => {
                                this.bool_03 = true
                                this.carregado = this.carregado + 4.60
                              })  

                                
                    Inadimplencia.total_recebimento_30_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Vencimento", this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.total_recebimento_30_v2 = response.data;                                
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.bool_04 = true
                            this.carregado = this.carregado + 4.60
                          })  


                      Inadimplencia.total_recebimento_30_60_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Vencimento", this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              this.total_recebimento_30_60_v2 = response.data;                                
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                              this.bool_05 = true
                              this.carregado = this.carregado + 4.60
                            })  


                      Inadimplencia.total_recebimento_60_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Vencimento", this.selectedOpcao, this.produto).then(response => {
                            if(response.status === 200){
                              this.total_recebimento_60_v2 = response.data;                                
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                              this.bool_06 = true
                              this.carregado = this.carregado + 4.60
                            })  

                      Inadimplencia.recebido_adiantado_vencimento_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){

                              try{
                                this.recebido_adiantado_vencimento_v2 = response.data;    
                              } catch (error) {
                              }  
                            } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.bool_07 = true
                            this.carregado = this.carregado + 4.60
                        })  

                        
      },

      async dashboard_1() {

            this.selectMode = "Vencimento";
            const formattedStartDate = converteDataParaFormatoISOAno(this.selectedStartDate, 1);
            const formattedEndDate = converteDataParaFormatoISOAno(this.selectedEndDate, 1);

            const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                    if (diferencaEmDias > 365) { 
                        return 
                      }
                  
                          
                      Inadimplencia.total_esperado_periodo_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this._1_esperado_periodo_v2 = response.data;   
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                          this.bool_08 = true
                          this.carregado = this.carregado + 6
                        })  




                        Inadimplencia.total_recebimento_vencimento_v2(formattedStartDate, formattedEndDate,  this.selectedCompany, this.selectedType, "Vencimento", null, null, this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              this._1_recebido_periodo_vencimento_v2 = response.data;      
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                              this.bool_09 = true
                              this.carregado = this.carregado + 4.60
                            })  

                        Inadimplencia.recebido_adiantado_vencimento_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode,  this.selectedOpcao, this.produto).then(response => {

                              if(response.status === 200){
                                try{
                                  this._1_recebido_adiantado_vencimento_v2 = response.data;    
                                } catch (error) {
                                  
                                }  
                              } 

                              }).catch(e => {    
                              }).finally(() => {
                                this.bool_10 = true
                                this.carregado = this.carregado + 4.60
                              })  


                        Inadimplencia.inadimplencia_acumulda(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Vencimento", this.selectedOpcao, this.produto).then(response => {

                            if(response.status === 200){
                              this._1_inadimplencia_acumulda = response.data;      
                            } 

                            }).catch(e => {    
                            }).finally(() => {
                              this.bool_11 = true
                              this.carregado = this.carregado + 4.60
                            })  

                              
                  Inadimplencia.total_recebimento_30_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Vencimento", this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this._1_total_recebimento_30_v2 = response.data;                                
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                          this.bool_12 = true
                          this.carregado = this.carregado + 4.60
                        })  


                    Inadimplencia.total_recebimento_30_60_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Vencimento", this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this._1_total_recebimento_30_60_v2 = response.data;                                
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.bool_13 = true
                            this.carregado = this.carregado + 4.60
                          })  


                    Inadimplencia.total_recebimento_60_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Vencimento", this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this._1_total_recebimento_60_v2 = response.data;                                
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.bool_14 = true
                            this.carregado = this.carregado + 4.60
                          })  


                   

                      
        },

        async dashboard_2() {

        this.selectMode = "Vencimento";
        const formattedStartDate = converteDataParaFormatoISOAno(this.selectedStartDate, 2);
        const formattedEndDate = converteDataParaFormatoISOAno(this.selectedEndDate, 2);
        
        const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                if (diferencaEmDias > 365) {
                  return 
                }
              
                  Inadimplencia.total_esperado_periodo_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos", this.selectedOpcao, this.produto).then(response => {

                    if(response.status === 200){
                      this._2_esperado_periodo_v2 = response.data; 

                    } 

                    }).catch(e => {    
                    }).finally(() => {
                      this.bool_15 = true
                      this.carregado = this.carregado + 6
                    })  




                    Inadimplencia.total_recebimento_vencimento_v2(formattedStartDate, formattedEndDate,  this.selectedCompany, this.selectedType, "Vencimento", null, null, this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this._2_recebido_periodo_vencimento_v2 = response.data;      
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                          this.bool_16 = true
                          this.carregado = this.carregado + 4.80
                        })  

                    Inadimplencia.inadimplencia_acumulda(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Vencimento", this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this._2_inadimplencia_acumulda = response.data;      
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                          this.bool_17 = true
                          this.carregado = this.carregado + 4.60
                        })  

                          
                Inadimplencia.total_recebimento_30_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Vencimento", this.selectedOpcao, this.produto).then(response => {

                    if(response.status === 200){
                      this._2_total_recebimento_30_v2 = response.data;                                
                    } 

                    }).catch(e => {    
                    }).finally(() => {
                      this.bool_18 = true
                      this.carregado = this.carregado + 4.60
                    })  


                Inadimplencia.total_recebimento_30_60_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Vencimento", this.selectedOpcao, this.produto).then(response => {

                      if(response.status === 200){
                        this._2_total_recebimento_30_60_v2 = response.data;                                
                      } 

                      }).catch(e => {    
                      }).finally(() => {
                        this.bool_19 = true
                        this.carregado = this.carregado + 4.60
                      })  


                Inadimplencia.total_recebimento_60_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Vencimento", this.selectedOpcao, this.produto).then(response => {

                      if(response.status === 200){
                        this._2_total_recebimento_60_v2 = response.data;                                
                      } 

                      }).catch(e => {    
                      }).finally(() => {
                        this.bool_20 = true
                        this.carregado = this.carregado + 4.60
                      })  


                Inadimplencia.recebido_adiantado_vencimento_v2(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode,  this.selectedOpcao, this.produto).then(response => {

                      if(response.status === 200){

                        try{
                          this._2_recebido_adiantado_vencimento_v2 = response.data;    
                        } catch (error) {
                          
                        }  
                      } 

                    }).catch(e => {    
                    }).finally(() => {
                      this.bool_21 = true
                      this.carregado = this.carregado + 4.60
                  })  

                  
        },
   
    
      calcularTotalAcumuladoFuneraria(lista) {

        this.funeraria_previda = 0;

          for (const item of lista) {

            if (item.label === 'PREVIDA') {

              this.funeraria_previda += item.qtd;
            }
          }
      },

      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },

    calcularPercentual(valorParcial, valorTotal) {
      if (valorTotal === undefined || valorTotal === 0) {
          return 0; // Evitar divisão por zero ou valor indefinido
      }
     
      if (valorParcial === undefined || valorParcial === 0) {
          return 0; // Evitar divisão por zero ou valor indefinido
      }

      const percentual = ((valorParcial / valorTotal) * 100).toFixed(2);
      return percentual.replace(".", ",") + "%";
    },

    calcularPercentualInad(valorParcial, valorTotal) {
      if (valorTotal === undefined || valorTotal === 0) {
          return "0%"; // Evitar divisão por zero
        }

        if (valorParcial === undefined || valorParcial === 0) {
          return 0; // Evitar divisão por zero ou valor indefinido
      }


        const complemento = ((1 - (valorParcial / valorTotal)) * 100).toFixed(2);
        return complemento.replace(".", ",") + "%";
      },

      dataLabel(dataNoFormatoBR, anos) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${dia}/${mes}/${ano - anos}`;

              }
              return null; // Retorna null se a data estiver em um formato inválido
            },

     


      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },


        clear () {
          this.$refs.form.reset();
        },

        descobrirMeses() {
          const anoAtual = new Date().getFullYear(); // Obtém o ano atual
          const mesAtual = new Date().getMonth(); // Obtém o ano atual

          for (let mes = (mesAtual-4); mes <= mesAtual; mes++) {
            this.categoria.push(formatMonthName(mes));
          }

        },



     
    },
  }

   
  function formatMoeda(value) {
                      // Use o método toLocaleString com a opção style: 'currency' para formatar como moeda

                      if (value === undefined || value === null) {
                          return 'R$ 0,00'; // Ou você pode retornar 0 como número, se preferir.
                        }

                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                      //return 0
                      //return value
                  }

  function getMonthName(monthNumber) {
    const monthNames = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    if (monthNumber >= 1 && monthNumber <= 12) {
        return monthNames[monthNumber - 1];
    } else {
        return ""; // Retorna uma string vazia para números de mês inválidos
    }
}

  function parseDate(dateString) {
    const parts = dateString.split('/');
    // Lembre-se que os meses em JavaScript começam do zero
    const year = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[0], 10);
    return new Date(year, month, day);
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            };

  function converteDataParaFormatoISOAno(dataNoFormatoBR, anos) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano - anos }-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            };

  function formatMonthName(month) {
    const monthNames = [
        "JAN", "FEV", "MAR", "ABR", "MAI", "JUN",
        "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"
    ];

    if (month >= 1 && month <= 12) {
        return monthNames[month - 1];
    } else {
        return ""; // Retorna uma string vazia para números de mês inválidos
    }
  }


</script>
<style scoped>
   
    table {
        width: 100%;
        margin-right: 2%;
        margin-left:20px;
       /* border-collapse: collapse;*/
    }
    th, td {
        text-align: left;
        padding: 8px;
    }
    th {
        background-color: #f2f2f2;
    }
    tr:nth-child(odd) td {
      /*  background-color: #4782b447;*/
    }
 
  
</style>